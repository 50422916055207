<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="productData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No products found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          products List
        </b-link>
        for other prducts.
      </div>
    </b-alert>

    <template v-if="productData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <store-product-info-card :product="productData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store"
import router from "@/router"
import { ref } from "@vue/composition-api"

import StoreProductInfoCard from "./StoreProductInfoCard.vue"

export default {
  components: {
    // Local Components
    StoreProductInfoCard,
  },
  setup() {
    const productData = ref(null)
    store
      .dispatch(
        "storeProducts/fetchStoreProduct",
        router.currentRoute.params.store_product_id
      )
      .then((response) => {
        productData.value = response
      })
      .catch((error) => {
        console.error(error)
      })

    return {
      productData,
    }
  },
}
</script>

<style></style>
