<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'apps-e-commerce-shop' }">
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <div v-if="product" class="position-relative">
      <div class="starred">
        <i
          class="fa-star fa-2x"
          :class="$route.params.starred ? 'fas' : 'far'"
          @click="
            starProduct({
              store_id: $route.params.store_id,
              store_product_id: $route.params.store_product_id,
              is_favorite: $route.params.starred,
            })
          "
        />
      </div>
      <b-row class="my-2 mx-0">
        <!-- Left: Product Image Container -->
        <b-col
          cols="12"
          md="5"
          lg="4"
          class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-center w-100">
            <b-img
              :src="`${product.product_attributes.logo}`"
              :alt="`Image of ${product.product_attributes.name}`"
              class="product-img"
              rounded
              fluid
            />
          </div>
        </b-col>

        <!-- Right: Product Details -->
        <b-col cols="12" md="7" lg="8">
          <!-- Product Name -->
          <h4>{{ product.product_attributes.name }}</h4>

          <!-- Product Brand -->
          <b-card-text class="item-company mb-0">
            <p class="mb-0">
              {{ product.product_attributes.variant }}
            </p>
          </b-card-text>

          <!-- Price And Ratings -->
          <div
            v-if="product.product_attributes.is_edible"
            class="d-flex align-items-center my-1"
          >
            <div class="ecommerce-details-price d-flex flex-wrap">
              <nutri-score
                v-if="
                  product.product_attributes.nutritional_info &&
                  product.product_attributes.is_edible
                "
                :nutritional-info="product.product_attributes.nutritional_info"
              />
            </div>
          </div>

          <!-- Avability -->
          <b-card-text
            >Producto -
            <span
              :class="
                product.product_attributes.active_status
                  ? 'text-success'
                  : 'text-danger'
              "
              >{{
                product.product_attributes.active_status
                  ? "Activo"
                  : "Desactivado"
              }}</span
            ></b-card-text
          >

          <!-- Product Description -->

          <ul
            v-if="this.product.description"
            class="mt-2"
            style="text-align: left"
          >
            <li
              class="mb-50"
              style="list-style: inside"
              v-for="(item, index) in getDescriptionArray(
                this.product.description
              )"
              :key="index"
            >
              {{ capitalize(item) }}
            </li>
          </ul>

          <!-- Colors -->
          <div class="product-color-option pt-0">
            <h6>Categorias</h6>
            <ul class="list-unstyled m-0">
              <li
                v-for="category in product.product_attributes.categories_names"
                :key="`category-${category.id}`"
                class="d-inline-block"
              >
                <b-badge>{{ category }}</b-badge>
              </li>
            </ul>
          </div>

          <hr />

          <div class="d-flex flex-column flex-sm-row">
            <div class="d-flex align-items-center">
              <i class="fas fa-barcode fa-2x mr-1" />
              {{ product.product_attributes.sku }}
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import Ripple from "vue-ripple-directive"
import { mapActions } from "vuex"

import NutriScore from "@/@core/components/NutriScore.vue"

export default {
  directives: {
    Ripple,
  },
  components: {
    NutriScore,
  },

  props: {
    product: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions("storeProducts", ["starProduct"]),

    getDescriptionArray(description) {
      if (typeof description === "string") {
        return description.split(". ")
      } else {
        return []
      }
    },
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.starred {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
</style>
